import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"

export default function SVGIcon({ svg, gatsbyImageData, file, alt, color }) {
  if (file.contentType === "image/svg+xml") {
    if (svg && svg?.content) {
      return (
        <div
          style={{ fill: color, width: "100%", height: "100%", color: color }}
          dangerouslySetInnerHTML={{ __html: svg?.content }}
        />
      )
    }

    return <img width={"100%"} height={"100%"} src={svg.dataURI} alt={alt} />
  }

  return <GatsbyImage image={gatsbyImageData} alt={alt} />
}
