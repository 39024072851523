import * as React from "react"
import { Box, Flex, FlexList, Space, Text } from "./ui"
import SVGIcon from "./svg-icon"
import { list, listItem, listItemText } from "./custom-icon-list.css"

export default function CustomIconList(props) {
  const extractListItems = (html) => {
    const filteredListItems = []
    const regex = /<li>(.*?)<\/li>/g
    let match
    while ((match = regex.exec(html)) !== null) {
      filteredListItems.push(match[1])
    }
    return filteredListItems
  }

  const listItems = extractListItems(props.body)

  return (
    <FlexList
      className={list}
      style={!props.isBigVersion ? { gap: "0" } : {}}
      variant={"columnStart"}
    >
      {listItems.map((listElement, index) => (
        <Flex as="li" key={index}>
          <Box
            style={
              props.isBigVersion
                ? {
                    width: "1.6rem",
                    minWidth: "1.6rem",
                    height: "1.6rem",
                    minHeight: "1.6rem",
                    top: "4px",
                    lineHeight: "1.6rem",
                    alignSelf: "flex-start",
                    position: "relative",
                  }
                : {
                    width: "1rem",
                    minWidth: "1rem",
                    height: "1rem",
                    minHeight: "1rem",
                    top: "5px",
                    lineHeight: "1rem",
                    alignSelf: "flex-start",
                    position: "relative",
                  }
            }
          >
            <SVGIcon
              svg={props.listImage.localFile?.svg}
              gatsbyImageData={
                props.localFile?.childImageSharp?.gatsbyImageData
              }
              file={props.listImage.file}
              color={props.color}
              alt={"icon"}
            />
          </Box>
          {props.isBigVersion && <Space size={3} />}
          <Text
            className={listItemText}
            dangerouslySetInnerHTML={{ __html: listElement }}
          />
        </Flex>
      ))}
    </FlexList>
  )
}
